import { decode as jsdecode, atob } from "js-base64";

export function decode(base64) {
    //return "";
  let data = jsdecode(base64);

  if (!data?.includes('"utf8":true')) {
    data = atob(base64);
  }

  return data;
}
