import * as Survey from "survey-knockout";

/* eslint-disable no-unused-vars */
import Inputmask from "inputmask";
/* eslint-disable no-unused-vars */
import CustomWidgetHelper from "./customwidgethelper";

const inputdate = {
  name: "inputdate",
  title: "Input Date",
  iconName: "",
  widgetIsLoaded: function () {
    //return typeof $ == "function" && !!$.fn.select2; //return true if jQuery and select2 widget are loaded on the page
    return true; //we do not require anything so we just return true.
  },
  isFit: function (question) {
    return question.getType() === "inputdate";
  },
  activatedByChanged: function (activatedBy) {
    Survey.JsonObject.metaData.addClass("inputdate", [], null, "empty");
    var properties = [
      {
        name: "dateFormat:string",
        category: "general",
        visible: true,
      },
    ];
    Survey.JsonObject.metaData.addProperties("inputdate", properties);
  },
  isDefaultRender: false,
  htmlTemplate: "<input class='form-control' type='date'/>",
  init() {
    Survey.Serializer.addProperty("inputdate", {
      name: "dateFormat:string",
      default: "dd.mm.yyyy",
      category: "general",
    });
  },
  afterRender: function (question, htmlElement) {
    if (
      htmlElement.parentElement.getElementsByClassName(
        "additional-properties"
      )[0]
    )
      return;

    if (question.isReadOnly) {
      const inputElement = htmlElement.getElementsByClassName("form-control");
      inputElement[0].disabled = true;
    }

    const mainDiv = document.createElement("div");
    mainDiv.classList.add("additional-properties");

    const clearButton = CustomWidgetHelper.appendClearButton(
      question,
      htmlElement
    );

    mainDiv.appendChild(clearButton);

    htmlElement.parentElement.appendChild(mainDiv, htmlElement);

    var input = htmlElement.getElementsByTagName("input")[0] || htmlElement;
    htmlElement.className += " input-date";

    var pushValueHandler = function () {
      question.value = input.value;
      clearValueHandler();
    };
    input.onfocusout = input.onchange = pushValueHandler;
    var clearValueHandler = function () {
      if (input.value) {
        clearButton.classList.remove("cleared");
        clearButton.disabled = false;
        htmlElement.classList.remove("cleared-question");
        question.survey.setValue(question.name + "_nda", undefined);
      }
      question.value = input.value;
    };
    input.onkeyup = clearValueHandler;

    var updateHandler = function () {
      input.value =
        question.value === undefined || question.value === null
          ? ""
          : question.value;
    };
    question.valueChangedCallback = updateHandler;
    updateHandler();
  },
};

export default inputdate;
