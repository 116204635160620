import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import auth from "../api/auth";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "100vh",
    overflowY: "auto",
  },
};

const SessionManager = () => {
  const tokenLifetime =
    new Date(window.token.expired_epoch * 1000).getTime() -
    new Date().getTime();
  const popupWarningTime = tokenLifetime - 1000 * 60; // open popup before 60 second of the session end if the lastActionTimeThreshold is met
  const lastActionTimeThreshold = 1000 * 60 * 5; //if there was an action within last 5 minutes auto refresh

  const [countDownTimer, setCountDownTimer] = useState(null);
  const [timeleft, setTimeLeft] = useState(0);
  const [popupState, setPopupState] = useState(false);
  const [expired, setExpired] = useState(false);

  const refreshToken = (callback) => {
    auth.refresh_token((err, res) => {
      if (err) {
        return;
      }

      window.lastActionTime = new Date().getTime();

      if (callback) callback();
    });
  };

  const updateLastActionTime = () => {
    window.lastActionTime = new Date().getTime();
    setPopupState(false);
  };

  const closeModal = () => {
    refreshToken(() => {
      clearInterval(countDownTimer);
      document.body.style.overflow = "unset";
      setPopupState(false);
    });
  };

  const PopupMessage = () => {
    let message = "Session ended";

    if (timeleft !== 0 && !expired) {
      message = (
        <>
          Session will end in <b>{timeleft}</b> seconds
        </>
      );
    }

    return (
      <Modal
        isOpen={popupState || expired}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Token Warning"
      >
        <div>{message}</div>
      </Modal>
    );
  };

  useEffect(() => {
    window.lastActionTime = new Date().getTime();

    document.addEventListener("keydown", updateLastActionTime);
    document.addEventListener("click", updateLastActionTime, false);

    return () => {
      document.removeEventListener("keydown", updateLastActionTime);
      document.removeEventListener("click", updateLastActionTime, false);
    };
  }, []);

  useEffect(() => {
    const getTimePassedFromLastAction = () => {
      const timeNow = new Date().getTime();
      const timePassedFromLastAction = timeNow - window.lastActionTime;

      return timePassedFromLastAction;
    };

    function showWarningPopup() {
      document.body.style.overflow = "hidden";

      const timerId = setInterval(() => {
        const timePassedFromLastAction = getTimePassedFromLastAction();
        let tokenTimeLeft = tokenLifetime - timePassedFromLastAction;

        if (tokenTimeLeft < 0) {
          tokenTimeLeft = 0;
          setExpired(true);
          clearInterval(timerId);
        }

        setTimeLeft(Math.ceil(tokenTimeLeft / 1000));
      }, 1000);

      const timePassedFromLastAction = getTimePassedFromLastAction();
      let tokenTimeLeft = tokenLifetime - timePassedFromLastAction;
      setTimeLeft(Math.ceil(tokenTimeLeft / 1000));

      setCountDownTimer(timerId);
      setPopupState(true);
    }

    const refreshTokenRoutine = () => {
      if (popupState) {
        return;
      }

      const timePassedFromLastAction = getTimePassedFromLastAction();

      if (timePassedFromLastAction >= lastActionTimeThreshold) {
        showWarningPopup();
      } else {
        refreshToken();
      }
    };

    const timer = setInterval(refreshTokenRoutine, popupWarningTime);

    return () => {
      clearInterval(timer);
    };
  }, [popupState, tokenLifetime, popupWarningTime, lastActionTimeThreshold]);

  return <PopupMessage />;
};

export default SessionManager;
