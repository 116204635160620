const yesno = {
  iconName: "icon-radiogroup",
  title: "Yes/No/Don't know",
  json: {
    title: {
      default: "Yes/No/Don't know question",
    },
    type: "radiogroup",
    choices: [
      {
        value: "item1",
        code: "1",
        calcValue: "1",
        text: {
          default: "Yes",
          de: "Ja",
        },
      },
      {
        value: "item2",
        code: "2",
        calcValue: "2",
        text: {
          default: "No",
          de: "Nein",
        },
      },
      {
        value: "item3",
        code: "3",
        calcValue: "3",
        text: {
          default: "Don't know",
          de: "Weiß nicht",
        },
      },
    ],
  },
};

export default yesno;
