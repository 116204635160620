import { checkTemplate } from "../helpers/template_utils";
import ApiModel from "./../api/api_model";
import Connector from "./../api/http_connector";
import { encode } from "js-base64";

const connector = new Connector({
  link: process.env.REACT_APP_API_LINK,
  endpoint: process.env.REACT_APP_ENDPOINT,
});
class apiModelAuth extends ApiModel {
  auth(callback) {
    if (!window.uri_params.otp_param && !window.uri_params.fid) {
      callback({ api_error: { message: "Data error: Keys dont exist" } });
      return;
    }
    const payload = {};
    payload.mount_name = "auth";
    payload.mount_type = "validate_OTP_keys";
    payload.params = {
      otp_param: window.uri_params.otp_param,
    };
    this._connectApi(payload, (err, res) => {
      if (!err) {
        window.uri_params.otp_param = res.api_data.otp_param_s64;
        window.token = res.api_data.token;
        window.auth = res.api_data.auth;
        callback(false, true);
      } else {
        if (err?.api_error?.message) callback(err);
        else callback({ api_error: { message: "Auth: Wrong keys" } });
      }
    });
  }
  refresh_token(callback) {
    const payload = {};
    payload.mount_name = "auth";
    payload.mount_type = "refresh_token";
    payload.auth = window.auth;

    this._connectApi(payload, (err, res) => {
      if (!err) {
        window.token = res.api_data.token;
        callback(false, res);
      } else if (err?.api_error?.message) {
        callback(err);
      } else {
        callback({ api_error: { message: "Auth: Failed to refresh token" } });
      }
    });
  }
  get_templates_list(fid, callback) {
    const payload = {};
    payload.auth = window.auth;
    payload.mount_name = "webtemplate";
    payload.mount_type = "get_templates_list";
    payload.params = {
      fid: fid,
    };
    this._connectApi(payload, (err, res) => {
      if (!err) {
        callback(false, res.api_data);
      } else {
        callback(err, res);
      }
    });
  }
  load_template(fid, callback) {
    const payload = {};
    payload.auth = window.auth;
    payload.mount_name = "webtemplate";
    payload.mount_type = "load_template";
    payload.params = {
      fid: fid,
    };
    this._connectApi(payload, (err, res) => {
      if (!err) {
        //window.uri_params.otp_param = res.api_data.otp_param_s64;
        callback(false, res.api_data);
      } else {
        callback(err, res);
      }
    });
  }
  save_template(template, callback) {
    const payload = {};
    payload.auth = window.auth;
    payload.mount_name = "webtemplate";
    payload.mount_type = "save_template";
    payload.params = {
      fid: window.uri_params.fid,
    };

    let arr_fragen = null;
    let convert_err = null;
    try {
      const templateObject = JSON.parse(template.text);

      if (!templateObject.autoSave) {
        templateObject.autoSave = false;
      }

      templateObject.utf8 = true;
      arr_fragen = encode(JSON.stringify(templateObject), true);
    } catch (err) {
      convert_err = err;
      console.error(err);
    }
    if (convert_err) {
      callback("Convert error", false);
      return;
    }
    const debug_data = checkTemplate(template.JSON);
    const data = {
      arr_fragen_json64: arr_fragen,
      no_of_quest: debug_data.questions_info.qty,
      no_of_quest_answered: 0,
      debug_data: debug_data,
    };
    payload.params.data = data;
    this._connectApi(payload, (err, res) => {
      if (!err) {
        //window.uri_params.otp_param = res.api_data.otp_param_s64;
        callback(false, res.api_data);
      } else {
        callback(err, res);
      }
    });
  }
}

const apiModel = new apiModelAuth(connector);

export default apiModel;
