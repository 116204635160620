import * as Survey from "survey-knockout";

/* eslint-disable no-unused-vars */
import noUiSlider from "nouislider";
/* eslint-disable no-unused-vars */

var widget = {
  name: "nouislider",
  title: "noUiSlider",
  iconName: "icon-nouislider",
  widgetIsLoaded: function () {
    return typeof noUiSlider != "undefined";
  },
  isFit: function (question) {
    return question.getType() === "nouislider";
  },
  htmlTemplate:
    '<div class="nouislider"><div class="nouislider_header"></div><div class="nouislider_body"></div></div>',
  activatedByChanged: function (activatedBy) {
    Survey.JsonObject.metaData.addClass("nouislider", [], null, "empty");
    Survey.JsonObject.metaData.addProperties("nouislider", [
      {
        name: "step:number",
        category: "slider",
        categoryIndex: 1,
        default: 1,
        minValue: 0,
      },
      {
        name: "rangeMin:number",
        category: "slider",
        default: 0,
      },
      {
        name: "rangeMax:number",
        category: "slider",
        default: 10,
      },
      {
        name: "headerMinText:string",
        category: "slider",
        default: "",
      },
      {
        name: "headerMaxText:string",
        category: "slider",
        default: "",
      },
      {
        name: "pipsMode",
        category: "slider",
        default: "steps",
        visible: false,
      },
      {
        name: "pipsValues:itemvalues",
        category: "slider",
        default: [0, 25, 50, 75, 100],
        visible: false,
      },
      {
        name: "pipsText:itemvalues",
        category: "slider",
        default: [0, 25, 50, 75, 100],
      },
      {
        name: "pipsDensity:number",
        category: "slider",
        default: 5,
        visible: false,
      },
      {
        name: "orientation",
        category: "slider",
        default: "horizontal",
        choices: ["horizontal", "vertical"],
        visible: false,
      },
      {
        name: "direction:string",
        category: "slider",
        choices: ["ltr", "rtl"],
        default: "ltr",
      },
      {
        name: "tooltips:boolean",
        category: "slider",
        default: true,
      },
    ]);
  },
  afterRender: function (question, el) {
    const header = el.children[0];

    const minValueText = document.createElement("div");
    minValueText.innerText = question.headerMinText ?? "";

    const maxValueText = document.createElement("div");
    maxValueText.innerText = question.headerMaxText ?? "";

    header.appendChild(minValueText);
    header.appendChild(maxValueText);

    el = el.children[1];
    el.style.marginBottom = "60px";
    if (question.orientation === "vertical") {
      el.style.height = "250px";
    }

    var slider = noUiSlider.create(el, {
      start: question.value || (question.rangeMin + question.rangeMax) / 2,
      connect: [true, false],
      step: question.step < 0 ? 0 : question.step,
      tooltips: question.tooltips && {
        to: function (numericValue) {
          if (Math.floor(question.step) !== question.step) {
            return numericValue.toFixed(2);
          }

          return numericValue;
        },
      },
      pips: {
        filter:
          question.pipsMode === "steps" &&
          function filterPips(value, type) {
            if (Math.floor(value) === value) {
              return 1;
            }

            return -1;
          },
        mode: question.pipsMode || "steps",
        values: question.pipsValues?.map(function (pVal) {
          var pipValue = pVal;
          if (pVal.value !== undefined) {
            pipValue = pVal.value;
          }
          //replaced parseInt to number to work with decimals
          return Number(pipValue);
        }),
        density: question.pipsDensity || 5,
        format: {
          to: function (pVal) {
            let pipText = pVal;
            question.pipsText?.map((el) => {
              //bug as pipValue always tried to be a number we need to compare numbers
              if (el.text !== undefined && Number(pVal) === Number(el.value)) {
                pipText = el.text;
              }
              return null;
            });

            return pipText;
          },
        },
      },
      range: {
        min: question.rangeMin,
        max: question.rangeMax,
      },
      orientation: question.orientation,
      direction: question.direction,
    });
    slider.on("change", function () {
      question.value = Number(slider.get());
    });
    var updateValueHandler = function () {
      slider.set(question.value);
    };
    if (question.isReadOnly) {
      el.setAttribute("disabled", true);
    }
    updateValueHandler();
    question.noUiSlider = slider;
    question.valueChangedCallback = updateValueHandler;
    question.readOnlyChangedCallback = function () {
      if (question.isReadOnly) {
        el.setAttribute("disabled", true);
      } else {
        el.removeAttribute("disabled");
      }
    };
  },
  willUnmount: function (question, el) {
    if (!!question.noUiSlider) {
      question.noUiSlider.destroy();
      question.noUiSlider = null;
    }
    question.readOnlyChangedCallback = null;
  },
  pdfRender: function (_, options) {
    if (options.question.getType() === "nouislider") {
      var point = options.module.SurveyHelper.createPoint(
        options.module.SurveyHelper.mergeRects.apply(null, options.bricks)
      );
      point.xLeft += options.controller.unitWidth;
      point.yTop +=
        options.controller.unitHeight *
        options.module.FlatQuestion.CONTENT_GAP_VERT_SCALE;
      var rect = options.module.SurveyHelper.createTextFieldRect(
        point,
        options.controller
      );
      var textboxBrick = new options.module.TextFieldBrick(
        options.question,
        options.controller,
        rect,
        true,
        options.question.id,
        options.question.value || options.question.defaultValue || "",
        "",
        options.question.isReadOnly,
        false,
        "text"
      );
      options.bricks.push(textboxBrick);
    }
  },
};

export default widget;
