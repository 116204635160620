const birthdate = {
  iconName: "icon-dropdown",
  title: "Birthdate",
  json: {
    title: "Birthdate",
    type: "inputdate",
    defaultValueExpression: "getPacientBirthdate()",
  },
};

export default birthdate;
