const pacientAge = {
  name: "pacientage",
  iconName: "icon-default",
  type: "pacientage",
  title: "Pacient Age",
  elementsJSON: [
    {
      name: "birthdate",
      title: "Pacient birth date",
      type: "inputdate",
      hasClearButton: false,
    },
    {
      name: "proceduredate",
      title: "Procedure date",
      type: "inputdate",
      hasClearButton: false,
    },
    {
      name: "pacientage",
      title: "Pacient age",
      type: "expression",
      hasClearButton: false,
    },
  ],
  onAfterRender(question, htmlElement) {
    htmlElement.className += " pacient-age";
  },
  onValueChanged: function (question) {
    const answers = question.value;

    if (!answers.birthdate || !answers.proceduredate) {
      if (answers.pacientage) {
        question.survey.setValue(question.name, {
          ...answers,
          pacientage: undefined,
        });
      }

      return;
    }

    question.survey.setValue(question.name, {
      ...answers,
      pacientage: calcFullYearAge(answers.birthdate, answers.proceduredate),
    });
  },
};

function calcFullYearAge(birthdateString, proceduredateString) {
  const birthdate = new Date(birthdateString);
  const proceduredate = new Date(proceduredateString);

  let yearDifference = proceduredate.getFullYear() - birthdate.getFullYear();

  if (
    birthdate.getMonth() > proceduredate.getMonth() ||
    (birthdate.getMonth() === proceduredate.getMonth() &&
      birthdate.getDate() > proceduredate.getDate())
  ) {
    yearDifference--;
  }

  return yearDifference;
}

export default pacientAge;
