//check for keys duplication

function getTitle(question, lang = "default") {
  const trans = getTranslation(question, lang) ?? "";
  return trans.length > 0 ? trans : "";
}

function getTranslation(translation, lang = "default") {
  return typeof translation === "object"
    ? translation?.[lang] || translation?.default
    : translation;
}

function getActiveToolboxComponents() {
  return [
    "text",
    "inputdate",
    "inputcustom",
    "checkbox",
    "radiogroup",
    "dropdown",
    "nouislider",
    "comment",
    "leadmatrix8v2",
    "leadprobes8v2",
    "expression",
  ];
}

function hasDefaultTranslation(element) {
  return (
    (element.title &&
      (typeof element.title === "string" || element.title.default)) ||
    (element.text && (typeof element.text === "string" || element.text.default))
  );
}

function checkQuestions(
  page,
  question_names,
  question_info,
  allowedComponents
) {
  if (!page.elements) return;

  page.elements.forEach((question) => {
    question_info.qty++;

    if (!question_names[question.name]) question_names[question.name] = 1;
    else {
      question_names[question.name]++;
      question_info.uniqness = false;
      question_info.duplicates[question.name] = question_names[question.name];
    }

    if (!allowedComponents.includes(question.type)) {
      question_info.compatible = false;
      question_info.legacy[question.name] = question_names[question.name];
    }

    if (!hasDefaultTranslation(question)) {
      question_info.hasDefaults = false;
      question_info.defaultles[question.name] = question_names[question.name];
    }

    if (question.choices) {
      question.choices.forEach((choice) => {
        if (!hasDefaultTranslation(choice)) {
          question_info.hasDefaults = false;
          question_info.defaultles[question.name] =
            question_names[question.name];
        }
      });
    }
  });
}
function checkTemplate(template) {
  const pages_info = {
    qty: 0,
    uniqness: true,
    duplicates: {},
    hasDefaults: true,
    defaultles: {},
  };
  const questions_info = {
    qty: 0,
    uniqness: true,
    compatible: true,
    duplicates: {},
    legacy: {},
    hasDefaults: true,
    defaultles: {},
  };
  const pages_names = [];
  const question_names = [];

  if (!template.pages) {
    return {
      status: pages_info.uniqness && questions_info.uniqness,
      pages_info: pages_info,
      questions_info: questions_info,
    };
  }

  const allowedComponents = getActiveToolboxComponents();

  template.pages.forEach((page) => {
    pages_info.qty++;

    if (!pages_names[page.name]) pages_names[page.name] = 1;
    else {
      pages_names[page.name]++;
      pages_info.uniqness = false;
      pages_info.duplicates[page.name] = pages_names[page.name];
    }

    if (!hasDefaultTranslation(page)) {
      pages_info.hasDefaults = false;
      pages_info.defaultles[page.name] = pages_names[page.name];
    }

    checkQuestions(page, question_names, questions_info, allowedComponents);
  });
  return {
    status:
      pages_info.uniqness &&
      pages_info.hasDefaults &&
      questions_info.uniqness &&
      questions_info.compatible &&
      questions_info.hasDefaults,
    pages_info: pages_info,
    questions_info: questions_info,
  };
}
function warningTemplate(template) {
  const template_stats = checkTemplate(template);

  if (template_stats.status) return null;

  let pages_error = "";
  if (!template_stats.pages_info?.uniqness) {
    const duplications = [];
    pages_error = "Pages duplications: ";
    Object.keys(template_stats.pages_info.duplicates).forEach((index) => {
      duplications.push(
        index + "(" + template_stats.pages_info.duplicates[index] + ")"
      );
    });
    pages_error += duplications.join(", ");
    pages_error += ". ";
  }

  if (!template_stats.pages_info?.hasDefaults) {
    const defaultles = [];
    pages_error += "Pages with no default translations: ";
    Object.keys(template_stats.pages_info.defaultles).forEach((index) => {
      defaultles.push(
        index + "(" + template_stats.pages_info.defaultles[index] + ")"
      );
    });
    pages_error += defaultles.join(", ");
    pages_error += ". ";
  }

  let questions_error = "";
  if (!template_stats.questions_info?.uniqness) {
    const duplications = [];
    questions_error = "Questions duplications: ";
    Object.keys(template_stats.questions_info.duplicates).forEach((index) => {
      duplications.push(
        index + "(" + template_stats.questions_info.duplicates[index] + ")"
      );
    });
    questions_error += duplications.join(", ");
  }

  if (!template_stats.questions_info?.hasDefaults) {
    const defaultles = [];
    questions_error += "Questions without default translations: ";
    Object.keys(template_stats.questions_info.defaultles).forEach((index) => {
      defaultles.push(
        index + "(" + template_stats.questions_info.defaultles[index] + ")"
      );
    });
    questions_error += defaultles.join(", ");
  }

  let legacy_questions = "";
  if (!template_stats.questions_info?.compatible) {
    const uncompatible = [];
    legacy_questions = "Legacy questions: ";
    Object.keys(template_stats.questions_info.legacy).forEach((index) => {
      uncompatible.push(
        index + "(" + template_stats.questions_info.legacy[index] + ")"
      );
    });
    legacy_questions += uncompatible.join(", ");
  }

  return pages_error + questions_error + legacy_questions;
}

const getQuestion = (template, question_id) => {
  let quest = {
    id: question_id,
    title: "",
  };
  template.pages.forEach((page) => {
    if (page.elements) {
      page.elements.forEach((question) => {
        if (question.name === question_id) {
          quest.title = question.title
            ? getTitle(question.title, "")
            : question.name;
        }
      });
    }
  });
  return quest;
};

const getDependencies = (template, question_id) => {
  let result = [];
  let key_idx = 0;
  let q_id = `{${question_id}}`;
  let q_id1 = `"${question_id}"}`;
  let q_id2 = `'${question_id}'}`;

  template.pages.forEach((page, idx) => {
    Object.keys(page).forEach((key) => {
      if (
        page[key].includes(q_id) ||
        page[key].includes(q_id1) ||
        page[key].includes(q_id2)
      ) {
        key_idx++;
        result.push({
          key_idx: key_idx,
          type: "page",
          page_nr: idx + 1,
          page_name: page.name,
          page_title: page.title ? getTitle(page.title) : page.name,
          quest_name: "",
          quest_type: "",
          quest_title: "",
          key: key,
          value: page[key],
        });
      }
    });
    if (page.elements) {
      page.elements.forEach((question) => {
        if (question.name !== question_id) {
          Object.keys(question).forEach((key) => {
            let str = JSON.stringify(question[key]);
            if (
              str.includes(q_id) ||
              str.includes(q_id1) ||
              str.includes(q_id2)
            ) {
              key_idx++;
              result.push({
                key_idx: key_idx,
                type: "question",
                page_nr: idx + 1,
                page_name: page.name,
                page_title: page.title ? getTitle(page.title) : page.name,
                quest_name: question.name,
                quest_type: question.type,
                quest_title: question.title ? getTitle(question.title) : "",
                key: key,
                value: str,
              });
            }
          });
        }
      });
    }
  });
  return result;
};

const getQuestionsInPage = (template, page_id) => {
  let quest_array = [];
  template.pages.forEach((page) => {
    if (page.name === page_id) {
      if (page.elements) {
        page.elements.forEach((question, idx) => {
          quest_array.push({
            key_idx: idx + 1,
            type: "question",
            page_nr: 1,
            page_name: page.name,
            page_title: page.title ? getTitle(page.title) : page.name,
            quest_name: question.name,
            quest_type: question.type,
            quest_title: question.title ? getTitle(question.title) : "",
            key: "",
            value: "",
          });
        });
      }
    }
  });
  return quest_array;
};

exports.checkTemplate = checkTemplate;
exports.warningTemplate = warningTemplate;
exports.getActiveToolboxComponents = getActiveToolboxComponents;
exports.getQuestion = getQuestion;
exports.getDependencies = getDependencies;
exports.getTranslation = getTranslation;
exports.getTitle = getTitle;
exports.getQuestionsInPage = getQuestionsInPage;
