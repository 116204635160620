const proceduredate = {
  iconName: "icon-dropdown",
  title: "Procedure date",
  json: {
    title: "Procedure date",
    type: "inputdate",
    defaultValueExpression: "getProcedureDate()",
  },
};

export default proceduredate;
