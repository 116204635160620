import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "100vh",
    overflowY: "auto",
    textAlign: "center",
  },
};

const CustomConfirm = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => {
        props.onDecision(true);
      }}
      style={customStyles}
      contentLabel="Token Warning"
    >
      <div style={{ padding: "10px" }}>{props.msg}</div>
      <div
        style={{
          justifyContent: "space-around",
          display: "flex",
          gap: "30px",
        }}
      >
        <button onClick={() => props.onDecision(true)}>Yes</button>
        <button onClick={() => props.onDecision(false)}>No</button>
      </div>
    </Modal>
  );
};

export default CustomConfirm;
