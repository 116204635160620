import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import SurveyCreatorComponent from "./component_editor";
import WaitingForm from "./component_waiting";
import ErrorForm from "./component_error";
import parseUri from "./helpers/parseuri";
import auth_connector from "./api/auth";
import SessionManager from "./helpers/session";
import { decode } from "./helpers/base64decode";

import Modal from "react-modal";

Modal.setAppElement("#root");

window.uri_params = parseUri(window.location.search);

const renderReactComponent = (component) => {
  ReactDOM.render(component, document.getElementById("root"));
};

renderReactComponent(<WaitingForm />);

auth_connector.auth((err, res) => {
  if (err) {
    renderReactComponent(<ErrorForm error_msg={err.api_error.message} />);
    return;
  }
  auth_connector.load_template(window.uri_params.fid, (err, res) => {
    if (err) {
      renderReactComponent(<ErrorForm error_msg={err?.api_error?.message} />);
    } else {
      let template = {};
      if (res.qarr.arr_fragen) {
        const json_template = decode(res.qarr.arr_fragen);
        template = json_template;
      } else {
        template = JSON.stringify({
          title: res.qarr.titel,
          description: atob(res.qarr.fbinfo), //kimio side left atob
        });
      }

      const isActive = res?.qarr?.fbstatus?.toString() !== "0";

      renderReactComponent(
        <>
          <SessionManager />
          <SurveyCreatorComponent
            isActive={isActive}
            params={window.uri_params}
            template={template}
          />
        </>
      );
    }
  });
});

reportWebVitals();
