import React from "react";
import Modal from "react-modal";
import "./deleteelementpopup.css"

const DeleteElementPopup = (props) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflowY: "auto",
      textAlign: "center",
    },
  };

  let msg= "Сonfirm deletion of this element"
  let warning =""
  if (props.depemdencies.length>0) {
    if (props.type==="page") {
      msg="You are trying to delete this page, but it contains some questions"
      warning="To delete this page, please remove shown questions."
    } else {
      msg="You are trying to delete this element, but it is used in other elements"
      warning="To delete this question, please remove shown dependend elements."
    }
  }


  const renderToolBox =() => {
    if (props.depemdencies.length>0) {
      return (
        <div className={"toolbox cancel"}>
          <div className={"toolbox warning"}>{warning}</div>
          <button className={"btn btn-primary"} onClick={() => props.onDecision (false)}>Close</button>
        </div>
      )
    } else {
        return (
          <div className={"toolbox btn-2"}     >
            <button className={"btn btn-primary"} onClick={() => props.onDecision(true)}>Delete</button>
            <button className={"btn btn-primary cancel"} onClick={() => props.onDecision(false)}>Cancel</button>
          </div>
        )
      }
  }

  const renderDependRows = (rows_data) => {
    let rows=[]
    let rows_page=rows_data.filter ((f) => f.type === "page");
    let rows_elements=rows_data.filter ((f) => f.type !== "page");

    if (rows_page.length>0) {
      rows_page.forEach((row) =>{
        rows.push(
          <div key={row.key_idx} className={"rows-data"}>
            <span className={"col type"}>{"page"}</span>
            <span className={"col one"}>{row.page_name}</span>
            <span className={"col two"}>{row.page_title}</span>
            <span className={"col three"}>{row.key}</span>
            <span className={"col four"}>{row.value}</span>
          </div>
        )
      })
    }
    if (rows_elements.length>0) {
      rows_elements.forEach((row) =>{
        rows.push(
          <div key={row.key_idx} className={"rows-data"}>
            <span className={"col type"}>{"question"}</span>
            <span className={"col one"}>{row.quest_name}</span>
            <span className={"col two"}>{row.quest_title}</span>
            <span className={"col three"}>{row.key}</span>
            <span className={"col four"}>{row.value}</span>
          </div>
        )
      })
    }
    return rows
  }

  const renderPageQuestionsRows = (rows_data) => {
    let rows=[]
    rows_data.forEach((row) =>{
        rows.push(
          <div key={row.key_idx} className={"rows-data-pg"}>
            <span className={"col type"}>{"question"}</span>
            <span className={"col one"}>{row.quest_name}</span>
            <span className={"col two"}>{row.quest_title}</span>
          </div>
        )
      })
    return rows
  }
  const renderDependencies =() => {
    if (props.depemdencies.length>0) {
      let rows = []
      if (props.type === "question") {
        let pages_list = []
        props.depemdencies.forEach ((pg) => {
          if (!pages_list.some ((el) => el.page_name === pg.page_name)) {
            if (pg.page_title !== pg.page_name) {
              pages_list.push ({ page_nr: pg.page_nr, page_name: pg.page_name, page_title: pg.page_title, });
            } else {
              pages_list.push ({ page_nr: pg.page_nr, page_name: pg.page_name, page_title: pg.page_name, });
            }
          }
        })


        pages_list.forEach ((pg) => {
          let rows_page = props.depemdencies.filter ((f) => f.page_nr === pg.page_nr);
          rows.push (
            <div key={pg.page_nr} className={"page-container"}>
              <div className={"page-title"}>
                <span className={"col-title"}>{pg.page_nr}</span>
                <span className={"col-title"}>{pg.page_title}</span>
              </div>
              <div className={"rows-container"}>
                {renderDependRows (rows_page)}
              </div>
            </div>
          )
        })

        return (
          <div className={"dependencies"}>
            <div key={"rowsheader"} className={"rows-data header"}>
              <span className={"col type"}>{"Dependencies"}</span>
              <span className={"col one"}>{"Field Id"}</span>
              <span className={"col two"}>{"Title"}</span>
              <span className={"col three"}>{"Logic type"}</span>
              <span className={"col four"}>{"Expression"}</span>
            </div>
            {rows}
          </div>
        )
      } else {
        rows.push (
          <div key={props.depemdencies[0].page_nr} className={"page-container"}>
            <div className={"rows-container"}>
              {renderPageQuestionsRows (props.depemdencies)}
            </div>
          </div>
        )
        return (
          <div className={"dependencies"}>
            <div key={"rowsheader"} className={"rows-data-pg header"}>
              <span className={"col type"}>{"type"}</span>
              <span className={"col one"}>{"Id"}</span>
              <span className={"col two"}>{"title"}</span>
            </div>
            {rows}
          </div>
        )
      }
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => {
        props.onDecision(true);
      }}
      style={customStyles}
      contentLabel="Delete element"
    >
      <div className={"delete-popup"}>
        <div className={"title"}>{msg}</div>
        {renderDependencies()}
        {renderToolBox()}
      </div>

    </Modal>
  );
};

export default DeleteElementPopup;
