const leadMatrix8Combined = {
  name: "leadmatrix8v2",
  iconName: "icon-default",
  title: "Lead Position",
  elementsJSON: [
    {
      name: "left",
      title: "Left",
      type: "matrixdropdown",
      columns: [
        {
          name: "manufacturer",
          title: "Manufacturer",
          cellType: "dropdown",
          showOptionsCaption: "true",
          optionsCaption: " ",
          choices: [
            {
              value: "medtronic",
              text: "Medtronic",
            },
            {
              value: "axion",
              text: "Axonics",
            },
            {
              value: "others",
              text: "Others",
            },
          ],
        },
        {
          name: "options",
          title: "Type of lead",
          cellType: "dropdown",
          showOptionsCaption: "true",
          optionsCaption: " ",
          choices: [
            {
              value: "test",
              text: "Test",
            },
            {
              value: "tined_lead",
              text: "Tined Lead",
            },
          ],
        },
        {
          name: "comment",
          title: "Comment",
          cellType: "text",
        },
      ],
      rows: ["S1", "S2", "S3", "S4"],
    },
    {
      name: "right",
      title: "Right",
      startWithNewLine: false,
      type: "matrixdropdown",
      columns: [
        {
          name: "manufacturer",
          title: "Manufacturer",
          cellType: "dropdown",
          showOptionsCaption: "true",
          optionsCaption: " ",
          choices: [
            {
              value: "medtronic",
              text: "Medtronic",
            },
            {
              value: "axion",
              text: "Axonics",
            },
            {
              value: "others",
              text: "Others",
            },
          ],
        },
        {
          name: "options",
          title: "Type of lead",
          cellType: "dropdown",
          showOptionsCaption: "true",
          optionsCaption: " ",
          choices: [
            {
              value: "test",
              text: "Test",
            },
            {
              value: "tined_lead",
              text: "Tined Lead",
            },
          ],
        },
        {
          name: "comment",
          title: "Comment",
          cellType: "text",
        },
      ],
      rows: ["S1", "S2", "S3", "S4"],
    },
  ],
  onAfterRender(question, htmlElement) {
    htmlElement.className += " leads8";
  },
};

export default leadMatrix8Combined;
