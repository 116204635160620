import * as Survey from "survey-knockout";
import CustomWidgetHelper from "./customwidgethelper";

var clearAnswer = {
  name: "searchstring",
  isFit: function (question) {
    const names = [
      "manufacturer",
      "options",
      "comment",
      "left",
      "right",
      "side_enabled",
      "probes",
      "electrode_configuration",
      "amplitude_type",
      "amplitude_v",
      "amplitude_ma",
      "pulse_width",
      "frequency",
      "L1",
      "L2",
      "birthdate",
      "proceduredate",
    ];

    const types = ["inputdate", "panel", "expression", "nouislider"];

    if (!types.includes(question.getType()) && !names.includes(question.name)) {
      return true;
    }

    return false;
  },
  init() {
    Survey.Serializer.addProperty("question", {
      name: "hasClearButton:switch",
      displayName: 'Has "No Data" button',
      category: "general",
      default: true,
    });
  },
  isDefaultRender: true,
  willUnmount: function (question, el) {},
  afterRender: function (question, htmlElement) {
    if (
      htmlElement.parentElement.getElementsByClassName(
        "additional-properties"
      )[0]
    )
      return;

    const mainDiv = document.createElement("div");
    mainDiv.classList.add("additional-properties");

    const clearButton = CustomWidgetHelper.appendClearButton(
      question,
      htmlElement
    );

    mainDiv.appendChild(clearButton);

    htmlElement.parentElement.appendChild(mainDiv, htmlElement);

    const input = htmlElement.getElementsByTagName("input")[0] || htmlElement;

    const clearValueHandler = function () {
      clearButton.classList.remove("cleared");
      clearButton.disabled = false;
      htmlElement.classList.remove("cleared-question");
      question.survey.setValue(question.name + "_nda", undefined);
    };
    input.onkeyup = input.onchange = clearValueHandler;
    question.valueChangedCallback = clearValueHandler;
  },
};

export default clearAnswer;
