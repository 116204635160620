import * as Survey from "survey-knockout";
import Inputmask from "inputmask";
import CustomWidgetHelper from "./customwidgethelper";

const inputcustom = {
  name: "inputcustom",
  title: "Input Custom",
  iconName: "",
  widgetIsLoaded: function () {
    return true;
  },
  isFit: function (question) {
    return question.getType() === "inputcustom";
  },
  activatedByChanged: function (activatedBy) {
    Survey.JsonObject.metaData.addClass("inputcustom", [], null, "empty");
    var properties = [
      {
        name: "decimalPlaces:number",
        category: "general",
        visible: true,
      },
      {
        name: "minValue:number",
        category: "general",
        visible: true,
      },
      {
        name: "maxValue:number",
        category: "general",
        visible: true,
      },
      {
        name: "prefix",
        category: "general",
        visible: true,
      },
      {
        name: "suffix",
        category: "general",
        visible: true,
      },
    ];
    Survey.JsonObject.metaData.addProperties("inputcustom", properties);
  },
  isDefaultRender: false,
  htmlTemplate: "<input class='form-control' type='text'/>",
  init() {
    Survey.Serializer.addProperty("inputcustom", {
      name: "minValue:number",
      category: "general",
    });
    Survey.Serializer.addProperty("inputcustom", {
      name: "maxValue:number",
      category: "general",
    });
    Survey.Serializer.addProperty("inputcustom", {
      name: "decimals:number",
      category: "general",
    });
  },
  afterRender: function (question, htmlElement) {
    if (
      htmlElement.parentElement.getElementsByClassName(
        "additional-properties"
      )[0]
    )
      return;

    if (question.isReadOnly) {
      const inputElement = htmlElement.getElementsByClassName("sv_q_text_root");
      if (inputElement[0]) inputElement[0].disabled = true;
    }

    const mainDiv = document.createElement("div");
    mainDiv.classList.add("additional-properties");

    const clearButton = CustomWidgetHelper.appendClearButton(
      question,
      htmlElement
    );

    mainDiv.appendChild(clearButton);

    htmlElement.parentElement.appendChild(mainDiv, htmlElement);

    const input = htmlElement.getElementsByTagName("input")[0] || htmlElement;

    htmlElement.className += " input-custom";
    const options = {};
    options.digits = question.decimalPlaces || 0;
    if (question.minValue !== undefined) options.min = question.minValue;
    if (question.maxValue !== undefined) options.max = question.maxValue;
    options.suffix = question.suffix || "";
    options.prefix = question.prefix || "";
    options.numericPlaceholder = "";
    options.rightAlign = false;
    options.showMaskOnFocus = false;
    options.showMaskOnHover = false;
    options.placeholder = "";
    options.radixPoint = ".";

    Inputmask("decimal", options).mask(input);

    var clearValueHandler = function () {
      if (input.value !== null && input.value !== undefined) {
        clearButton.classList.remove("cleared");
        clearButton.disabled = false;
        htmlElement.classList.remove("cleared-question");
        question.survey.setValue(question.name + "_nda", undefined);
      }
      question.value = input.value;
    };

    input.onkeyup = clearValueHandler;

    var updateHandler = function () {
      if (question.value === undefined || question.value === null)
        input.value = "";
    };
    question.valueChangedCallback = updateHandler;
    if (question.value) input.value = question.value;
    updateHandler();
  },
};

export default inputcustom;
