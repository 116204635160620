const gender = {
  iconName: "icon-radiogroup",
  title: "Gender",
  json: {
    title: {
      default: "Gender question",
      de: "Frage Geschlecht",
    },
    type: "radiogroup",
    choices: [
      {
        value: "male",
        code: "1",
        text: {
          default: "Men",
          de: "Mann",
        },
      },
      {
        value: "female",
        code: "2",
        text: {
          default: "Women",
          de: "Frauen",
        },
      },
    ],
  },
};

export default gender;
