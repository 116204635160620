import Inputmask from "inputmask";

const inputInteger = {
    name: "inputinteger",
    iconName: "icon-matrix",
    title: "Input INT",
    questionJSON: 
        {
            "type": "text",
            "inputType": "text"
        }
    ,
    onAfterRender(question, htmlElement) {
        htmlElement.className += ' input-integer';
        var input = htmlElement.querySelector("input") || htmlElement;
        const defaultMin = -100;
        const defaultMax = 1000;
        const min = (!isNaN(parseInt(question.minValue))) ? parseInt(question.minValue) : defaultMin;
        const max = (!isNaN(parseInt(question.maxValue))) ? parseInt(question.maxValue) : defaultMax;

       Inputmask("decimal", {
            groupSeparator: '',
            min: min,
            max: max,
            autoGroup: true,
            digitsOptional: true,
        }).mask(input);
    }
}

export default inputInteger;