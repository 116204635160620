class CustomWidgetHelper {
  static showClearButton = (question) => {
    return (
      question.hasClearButton &&
      !question.isRequired &&
      !question.isReadOnly &&
      !question.surveyValue.isDisplayMode
    );
  };

  static appendClearButton = (question, htmlElement) => {
    var btnEl = document.createElement("button");
    btnEl.innerHTML = "No data";
    btnEl.name = "clear_data";
    btnEl.className = "no-data-available";

    const nda_name = question.name + "_nda";

    if (question.survey.getValue(nda_name)) {
      btnEl.classList.add("cleared");
      btnEl.disabled = true;
      htmlElement.classList.add("cleared-question");
    }

    btnEl.onclick = function () {
      question.value = undefined;
      btnEl.classList.add("cleared");
      btnEl.disabled = true;
      htmlElement.classList.add("cleared-question");
      question.survey.setValue(nda_name, true);
    };

    const setClearButtonVisibility = () => {
      btnEl.style.display = !this.showClearButton(question) ? "none" : "";
    };

    setClearButtonVisibility();

    question.registerFunctionOnPropertyValueChanged(
      "hasClearButton",
      setClearButtonVisibility
    );

    question.registerFunctionOnPropertyValueChanged(
      "isRequired",
      setClearButtonVisibility
    );

    return btnEl;
  };
}

export default CustomWidgetHelper;
