import auth_connector from "./api/auth";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { decode } from "./helpers/base64decode";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "100vh",
    overflowY: "auto",
  },
};

const TemplateExplorer = (props) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState("-1");
  const [clickedId, setClickedId] = useState(null);
  const [templateData, setTemplateData] = useState([]);

  if (props.isModalOpen) {
    document.body.style.overflow = "hidden";
  }

  useEffect(() => {
    auth_connector.get_templates_list(window.uri_params.fid, (err, res) => {
      if (err) {
        console.log(err);
        return;
      }

      const result = [];

      for (const property in res) {
        if (res[property].id === window.uri_params.fid) {
          continue;
        }

        result.push({ id: res[property].id, titel: res[property].titel });
      }

      setOptions(result);
    });
  }, []);

  const getTemplateData = (event) => {
    const fid = event.target.value;

    setSelected(fid);

    auth_connector.load_template(fid, (err, res) => {
      if (err) {
        console.log(err);
        return;
      }

      if (res.qarr.arr_fragen) {
        let pages = [];
        const jsonString = decode(res.qarr.arr_fragen);
        try {
          pages = JSON.parse(jsonString)?.pages ?? [];
        } catch (e) {
          console.log("Invalid survey payload format");
        }
        setTemplateData(pages);
      }
    });
  };

  const Selector = (selectorProps) => {
    return (
      <select onChange={getTemplateData} value={selectorProps.value}>
        <option value={"-1"} disabled>
          Select template
        </option>
        {selectorProps.options.map((option) => {
          return (
            <option key={option.id} value={option.id}>
              {option.titel}
            </option>
          );
        })}
      </select>
    );
  };

  const getElement = (searchedId, surveyJson) => {
    let element = templateData.find((page) => {
      return page.name === searchedId;
    });

    if (element) {
      element = { ...element };
      element.name = getUniqueName(element.name, surveyJson);

      const elements = [...element.elements];

      for (let index = 0; index < elements.length; index++) {
        const question = { ...elements[index] };
        question.name = getUniqueName(question.name, surveyJson);
        elements[index] = question;
      }

      element.elements = elements;

      return element;
    }

    for (let page = 0; page < templateData.length; page++) {
      const pageElements = templateData[page].elements;
      element = pageElements?.find((question) => {
        return question.name === searchedId;
      });

      if (element) {
        break;
      }
    }

    element = { ...element };
    element.name = getUniqueName(element.name, surveyJson);

    return element;
  };

  const getUniqueName = (elementName, surveyObject) => {
    let uniqueName = elementName;
    let iteration = 1;
    let element = undefined;

    /* eslint-disable no-loop-func */
    do {
      element = surveyObject?.pages?.find((page) => {
        return page.name === uniqueName;
      });

      if (!element && surveyObject?.pages) {
        for (let page = 0; page < surveyObject.pages.length; page++) {
          const pageElements = surveyObject.pages[page]?.elements ?? [];
          element = pageElements.find((question) => {
            return question.name === uniqueName;
          });

          if (element) {
            break;
          }
        }
      }

      if (element) {
        uniqueName = elementName + "_" + iteration;
        iteration++;
      }
    } while (element);

    return uniqueName;
  };

  const OnItemClick = (event) => {
    setClickedId(event.target.id);
  };

  const OnDisapprove = () => {
    setClickedId(null);
  };

  const CloseModal = () => {
    setClickedId(null);

    document.body.style.overflow = "unset";

    props.onClose();
  };

  const OnApprove = () => {
    const surveyJson = { ...props.JSON };
    const element = getElement(clickedId, surveyJson);
    element.source = selected + "_" + clickedId;

    if (!element.type) {
      surveyJson.pages.push(element);
    } else {
      const pageToAppendQuestion = surveyJson.pages[props.activePage];

      if (!pageToAppendQuestion.elements) {
        pageToAppendQuestion.elements = [];
      }

      pageToAppendQuestion.elements.push(element);
    }
    setClickedId(null);
    props.onAdd(surveyJson);
  };

  const Toc = (tocProps) => {
    return (
      <div id="toc_container">
        <p className="toc_title">Contents</p>
        <ul className="toc_list">
          {tocProps.templateData
            .filter((f) => f.elements)
            .map((page, indexPage) => {
              return (
                <li key={page.name}>
                  <button id={page.name} onClick={OnItemClick}>
                    {indexPage + 1 + ". " + (page.title ?? page.name)}
                  </button>
                  <ul>
                    {page.elements.map((question, indexQuestion) => {
                      return (
                        <li key={question.name}>
                          <button id={question.name} onClick={OnItemClick}>
                            {indexPage +
                              1 +
                              "." +
                              (indexQuestion + 1) +
                              ". " +
                              (question.title?.default ??
                                question.title ??
                                question.name)}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
        </ul>
      </div>
    );
  };

  const isAlreadyAdded = (surveyId, questionId) => {
    const urn = surveyId + "_" + questionId;
    const pages = props?.JSON?.pages ?? [];

    let element = pages.find((page) => {
      return page.source === urn;
    });

    if (element) {
      return true;
    }

    for (let page = 0; page < pages.length; page++) {
      const pageElements = pages[page].elements;
      if (Array.isArray(pageElements)) {
        element = pageElements.find((question) => {
          return question.source === urn;
        });
      }

      if (element) {
        return true;
      }
    }

    return false;
  };

  const selectedElement = getElement(clickedId, null);
  const alreadyAdded = isAlreadyAdded(selected, clickedId);

  return (
    <Modal
      isOpen={props.isModalOpen}
      onRequestClose={CloseModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div>
        {clickedId && (
          <div>
            <div style={{ maxWidth: "40rem", marginBottom: "2rem" }}>
              <div>
                {"Are you sure you want to add "}
                <b>
                  {'"' + (selectedElement.title ?? selectedElement.name) + '"'}
                </b>
                {" to the survey?"}
              </div>
              {alreadyAdded && (
                <div style={{ color: "red" }}>*Element already added</div>
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <button onClick={OnApprove}>Confirm</button>
              <button onClick={OnDisapprove}>Cancel</button>
            </div>
          </div>
        )}
        {!clickedId && (
          <>
            <h3>
              Please select a question or a whole page to copy into your
              questionnaire
            </h3>
            <Selector options={options} value={selected} />
            <Toc templateData={templateData} />
          </>
        )}
      </div>
    </Modal>
  );
};

export default TemplateExplorer;
