import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "100vh",
    overflowY: "auto",
  },
};

const AutoSavePopup = (props) => {
  const closeModal = () => {
    document.body.style.overflow = "unset";
    props.closeModal();
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Token Warning"
    >
      <div>Enable auto save?</div>
      <div style={{ justifyContent: "space-around", display: "flex" }}>
        <button onClick={() => props.onSelect(true)}>Yes</button>
        <button onClick={() => props.onSelect(false)}>No</button>
      </div>
    </Modal>
  );
};

export default AutoSavePopup;
