const leadprobes8combined = {
    name: "leadprobes8v2",
    iconName: "icon-default",
    title: "Lead/IPG Programming",
    elementsJSON:
        [
            { type: "leadprobes_v2", "width": "50%", "maxWidth": "50%", "hideAnsweredStatus":true, name: "L1", title: { "default": "L1 - left side", "de": "L1" } },
            { type: "leadprobes_v2", "width": "50%", "maxWidth": "50%", "hideAnsweredStatus":true, name: "L2", title: { "default": "L2 - right side", "de": "L2" }, startWithNewLine: false }
        ],
    onAfterRender(question, htmlElement) {
        htmlElement.className += ' probes8';
    }
}

export default leadprobes8combined;