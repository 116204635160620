const yesno = {
  iconName: "icon-radiogroup",
  title: "Yes/No",
  json: {
    title: {
      default: "Yes/No question",
    },
    type: "radiogroup",
    choices: [
      {
        value: "item1",
        code: "1",
        calcValue: "1",
        text: {
          default: "Yes",
          de: "Ja",
        },
      },
      {
        value: "item2",
        code: "2",
        calcValue: "2",
        text: {
          default: "No",
          de: "Nein",
        },
      },
    ],
  },
};

export default yesno;
