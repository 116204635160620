const leadMatrix8Combined = {
    name: "leadmatrix8combined",
    iconName: "icon-default",
    title: "Lead Position",
    elementsJSON:
        [
            { type: "leadmatrix4left", name: "left", title: {"default":"Left","de":"Links"} },
            { type: "leadmatrix4right", name: "right", title: {"default":"Right","de":"Rechts"}, startWithNewLine: false }
        ],
    onAfterRender(question, htmlElement) {
        htmlElement.className += ' leads8';
    }
}

export default leadMatrix8Combined;